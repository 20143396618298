








































































































import { DataTableHeader } from 'vuetify'
import Base from '@/application/models/Base'
import { Component } from 'vue-property-decorator'
import Device from '@/application/models/Device'
import Patient from '@/application/models/Patient'
import { Getter } from 'vuex-class'

@Component({})
export default class DevicesOverviewView extends Base {
  @Getter('patients', { namespace: 'patients' })
  patients!: Array<Patient>

  @Getter('devices', { namespace: 'devices' })
  devices!: Array<Device>

  public search = ''
  headers: Array<DataTableHeader> = []

  public linkDialog = false
  public selectedDevice = {}
  public selectedPatient = {}

  constructor () {
    super('devices_overview')
  }

  created () {
    this.headers.push(
      {
        text: this.getLangText('table.header.id'),
        filterable: true,
        align: 'start',
        value: 'id'
      },
      {
        text: this.getLangText('table.header.battery'),
        filterable: false,
        align: 'start',
        value: 'battery'
      },
      {
        text: this.getLangText('table.header.actions'),
        filterable: false,
        align: 'start',
        value: ''
      }
    )
  }

  get devicesForDialog () {
    return this.devices.map((device: Device) => {
      return {
        text: 'GeräteID: ' + device.id,
        value: device
      }
    })
  }

  get patientForDialog () {
    return this.patients.map((patient: Patient) => {
      return {
        text: patient.fullName + ' - ' + patient.dataProviderPatientId,
        value: patient
      }
    })
  }

  public linkDeviceDialog (device: Device) {
    this.selectedDevice = device
    this.linkDialog = true
  }
}
